<template>
  <div class="header" :class="{ navMove: isMove }">
    <div class="header-wrap">
      <div class="img">
        <!-- <img src="../../assets/img/logo.png" alt="" /> -->
        <i class="icon icon-logo2 icon-size"></i>
        <!-- <span class="title">账号中心</span> -->
      </div>
      <div class="menu-part">
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="/"><div>首页</div></el-menu-item>
          <el-menu-item index="/solution"><div>解决方案</div></el-menu-item>
          <el-menu-item index="/browser"><div>证据验证</div></el-menu-item>
          <el-menu-item index="/bigScreen"><div>数据大屏</div></el-menu-item>
          <el-menu-item index="/accessDocuments"
            ><div>文档中心</div></el-menu-item
          >
          <el-menu-item index="/about"><div>关于我们</div></el-menu-item>
          <!-- <el-menu-item index="/treasureBox"
            ><div><i class="icon icon-treasurelogo icon-color"></i>产品百宝箱</div></el-menu-item
          > -->
          <el-menu-item index="/openSkill"><div><i class="icon icon-openlogo icon-color"></i>开放平台</div></el-menu-item>
          <el-menu-item index="/wallet">
            <i class="icon icon-qianbao-5 icon-color1"></i>
            <span>钱包</span>
          </el-menu-item>
          <el-submenu index="/count" v-if="isLogin()">
            <template slot="title">
              <i class="icon icon-gerenzhongxin icon-color2"></i>
            </template>
            <el-menu-item index="2-2" @click="loginOut">退出登录</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api";
import { getUserInfo, getApply, loginout } from "@/api/user";
import { statusCode } from "@/config";
import { setStorageItem, setCookie, delCookie, getCookie } from "@/utils";
import { redirectUrl, cookieDomainName } from "@/config/index.js";

export default {
  name: "AppPortalHeader",
  data() {
    return {
      isMove: false,
      loginInfo: {},
      urlArray: [
        "/",
        "/solution",
        "/browser",
        "/bigScreen",
        "/treasureBox",
        "/openSkill",
        "/about",
        "/accessDocuments"
      ]
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 10) {
        this.isMove = true;
      } else {
        this.isMove = false;
      }
    });
    this.getLoginInfo();
  },
  methods: {
    isLogin() {
      return getCookie("zxtoken");
    },
    handleSelect(val) {
      if (val == "/bigScreen") {
        window.open(`${window.toolbox_redirectUrl.portal}/bigScreen`, "_blank");
        return;
      }
      if (val == "/wallet") {
        // 钱包
        window.open(`${window.toolbox_redirectUrl.wallet}`, "_blank");
        return;
      }
      if (val == "/openSkill") {
        // 开放平台
        // https://testopen.zhixinblockchain.com/hashcertificate/overview
        window.open(
          `${window.toolbox_redirectUrl.open}/hashcertificate/overview`,
          "_blank"
        );
        return;
      }
      if (val == "/treasureBox") {
        // 百宝箱
        window.open(
          `${window.toolbox_redirectUrl.treasurechest}treasurebox`,
          "_blank"
        );
        return;
      }
      this.urlArray.forEach(ele => {
        if (ele === val) {
          window.open(`${window.toolbox_redirectUrl.portal}${ele}`, "_self");
        }
      });
    },
    // 企业认证信息查询
    getApply() {
      getApply().then(res => {
        this.$message.closeAll();
        if (res.detail) {
          // this.apply = res.detail;
          localStorage.setItem("userDetail", JSON.stringify(res.detail));
        } else {
          this.$message.error(res.retMsg);
        }
      });
    },
    loginOut() {
      this.$confirm("确定退出当前账号吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          loginout({})
            .then(res => {
              if (res.retCode === 0) {
                if (location.href.includes(window.toolbox_redirectUrl.open)) {
                  // 测试环境
                  // delCookie("zxtoken", ".zhixinblockchain.com");
                  delCookie("zxtoken", cookieDomainName);
                } else {
                  // 本地
                  delCookie("zxtoken"); // 兼容本地调试
                }

                sessionStorage.removeItem('openQuery')

                this.$message({
                  showClose: true,
                  message: "退出成功",
                  type: "success",
                  duration: 500,
                  onClose: () => {
                    this.$router.push({
                      name: "login"
                    });
                  }
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.retMsg,
                  type: "error"
                });
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    getLoginInfo() {
      if (getCookie("zxtoken")) {
        getUserInfo()
          .then(res => {
            if (res.retCode == 0) {
              this.loginInfo = res.detail;
              // setStorageItem('loginInfo', res.detail)
              if (res.detail.epId != 0) {
                this.getApply();
              }
            } else {
              this.$message({
                showClose: true,
                message: res.retMsg,
                type: "error"
              });
            }
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  min-width: 1200px;
  position: fixed;
  z-index: 1000;
  // background-image: linear-gradient(0deg, #0f276b 0%, #172d69 100%);
  background-color: #0f276b;
  .header-wrap {
    width: 1200px;
    height: 60px;
    line-height: 60px;
    margin: 0 auto;
    // border:1px solid red;
    display: flex;
    justify-content: space-between;
  }
  .img {
    // margin-left: 60px;
    display: flex;
    align-items: center;
     .icon-size {
      color: #fff;
      float: right;
      font-size: 40px;
    }
    // .title{
    //     margin-left: 19px;
    //     height: 26px;
    //     line-height: 26px;
    //     border-left: 1px solid #9DB9E2;
    //     color: #9DB9E2;
    //     padding-left:12px
    // }
  }
  .menu-part {
    // margin-right: 64px;
  }
  ::v-deep .menu-part {
    .el-menu-demo {
      border: none;
      background-color: transparent;
      .el-menu-item {
        color: #bacff6;
        padding: 0 16px;
        border: 2px solid transparent;
        // height: 16px;
        // line-height: 16px;
        transition: none;
        // border-right: 1px solid rgba(192, 205, 238, 0.35);
      }
      .el-submenu .el-submenu__title {
        // height: 20px;
        // line-height: 20px;
      }
      .el-submenu .el-submenu__title:hover {
        background: transparent;
      }
      .el-submenu .el-submenu__icon-arrow {
        display: none;
      }
      .el-menu-item:not(.is-disabled):focus,
      .el-menu-item:not(.is-disabled):hover {
        background-color: transparent;
      }
      .el-menu-item.is-active {
        color: #fff;
      }
      .el-menu--horizontal > .el-menu-item,
      .is-active {
        border-bottom: none !important;
      }
      .el-menu .el-menu--popup-bottom-start {
        margin-top: 30px;
      }
      .el-submenu.is-active .el-submenu__title {
        border-bottom: none;
      }
      .el-menu--horizontal > .el-menu-item {
        border-bottom: none !important;
      }
      .el-menu-item:last-child {
        border-right: none;
      }
    }
  }
}
.icon-color2{
 color: #bacff6;
 font-size: 20px;
}
.icon-color {
  color: #bacff6;
  font-size: 16px;
  margin-right:3px;
}
.icon-color1{
  color: #bacff6;
  font-size: 20px;
  margin-right:3px;
}
.navMove {
  background-color: #3b63df;
  transition: all 0.5s linear;
}
</style>
