<template>
  <div>
    <div class="header"   v-if="type != 1" >
      <div class="user-info">
        <div class="toggle-icon">
          <i
            class="icon"
            :class="isCollapse ? 'icon-weibiaoti25' : 'icon-weibiaoti26'"
            @click="toggleMenu"
          ></i>
        </div>
        <div>
          <div class="user-name">
            <span class="wallet-icon" @click="goWallet"
              ><i class="icon icon-qianbao-5"></i>钱包</span>
            <el-dropdown @command="handleCommand">
              <span class="open-el-dropdown">
                <img src="@/assets/defaultHead.png" class="headLogo" alt="" />{{loginInfo.email}}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="accountInfo">账号中心</el-dropdown-item>
                <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- <img @click="loginOut" src="../../assets/img/loginOut.png" alt="" />
          <span @click="loginOut" class="login-out">退出登录</span> -->

        </div>
      </div>
    </div>
    <AppPortalHeader v-if="type == 1"></AppPortalHeader>
  </div>
</template>

<script>
import { loginout, getUserInfo } from "@/api/user";
import { statusCode } from "@/config";
import { setStorageItem, setCookie, getCookie, delCookie } from "@/utils";
import { redirectUrl,cookieDomainName } from "@/config/index.js";
import AppPortalHeader from "@/components/layer/AppPortalHeader";

export default {
  components: {
    AppPortalHeader
  },
  name: "AppHeader",
  props: {
    type: {}
  },
  data() {
    return {
      loginInfo: {}
    };
  },
  created() {
    this.getLoginInfo();
  },
  mounted() {
  },
  computed: {
    isCollapse() {
      return this.$store.state.openplate.isCollapse;
    }
  },
  methods: {
    handleCommand(str){
      if(str == 'accountInfo'){
        this.goUserCenter()
      }else if(str == 'loginout'){
        this.loginOut()
      }
    },
    goUserCenter(){
        this.$router.push({
          path: "/accountInfo"
        })
    },
    goWallet(str) {
      window.open(window.toolbox_redirectUrl.wallet);
    },
    toHome() {
      // 根据路由进行判断,返回不同的地方
      const _href = location.href;
      if (location.href.indexOf("from=treasurechest") != -1) {
        window.location.href = window.toolbox_redirectUrl.treasurechest;
      } else if (location.href.indexOf("from=portal") != -1) {
        window.location.href = window.toolbox_redirectUrl.portal;
      } else {
        this.$router.push({
          path: "/"
        });
      }
    },
    // 切换左侧菜单
    toggleMenu() {
      const flag = this.$store.state.openplate.isCollapse;
      this.$store.commit("changeMenuToggle", !flag);
    },
    loginOut() {
      this.$confirm("确定退出当前账号吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          loginout({})
            .then(res => {
              if (res.retCode === 0) {
                if (location.href.includes(window.toolbox_redirectUrl.open)) {
                  // 测试环境
                  // delCookie("zxtoken", ".zhixinblockchain.com");
                   delCookie("zxtoken",cookieDomainName)
                } else {
                  // 本地
                  delCookie("zxtoken"); // 兼容本地调试
                }
                sessionStorage.removeItem('openQuery')
                this.$message({
                  showClose: true,
                  message: "退出成功",
                  type: "success",
                  duration: 500,
                  onClose: () => {
                    // this.$router.push({
                    //   name: "login"
                    // })
                    const openWin = window.open(`${window.toolbox_redirectUrl.portal}/openSkill`, '_self')
                    openWin.postMessage('closePage',`${window.toolbox_redirectUrl.portal}/openSkill`)
                  }
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.retMsg,
                  type: "error"
                });
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    getLoginInfo() {
      // 判断是否已经登陆，检查token信息
      if (!getCookie("zxtoken")) {
        return;
      }
      getUserInfo()
        .then(res => {
          if (res.retCode === 0) {
            this.loginInfo = res.detail;
            // setStorageItem("loginInfo", res.detail);
            localStorage.setItem("userData", JSON.stringify(res.detail));
          } else {
            this.$message({
              showClose: true,
              message: res.retMsg,
              type: "error"
            });
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 60px;
  // background-color: #152857;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .user-info {
    margin-right: 30px;
    font-size: 14px;
    color: #385abd;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggle {
      margin-left: 20px;
    }
    .toggle-icon {
      padding-left: 30px;
      box-sizing: border-box;
      .icon {
        font-size: 16px;
        cursor: pointer;
      }
    }
    .user-name {
      display: inline-block;
      margin-right: 30px;
      .open-el-dropdown{
        cursor: pointer;
        color:#385abd
      }

      .wallet-icon {
        i {
          font-size: 20px;
          margin-right: 3px;
        }
        cursor: pointer;
        margin-right: 25px;
      }
    }
    img {
      padding-right: 10px;
      vertical-align: middle;
      cursor: pointer;
    }
    .login-out {
      cursor: pointer;
    }
  }
}
.bg-blue {
  background-color: rgb(6, 32, 104);
}
.logoHead {
  float: left;
  margin: 15px 0 15px 62px;
}
</style>
