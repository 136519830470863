import axios from '@/axios'
// 获取客户列表
export const customerManagementList = data => axios.post('/api/admin/ep/list', data)
// 禁用企业用户
export const disableUser = data => axios.post('/api/admin/app/disablebyep', data)
// 启用企业用户
export const enableUser = data => axios.post('/api/admin/app/enablebyep', data)
// 企业用户审批
export const authUser = data => axios.post('/api/admin/ep/state/mod', data)
// 企业用户审批
export const getCategory = data => axios.get(`/api/trade/parent?pid=${data}`)
